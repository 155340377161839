<!-- 采购退货单记录-详情 -->
<template>
  <div class="pagePadding">
    <Table :productList="listColumns" :productData="listData"></Table>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'detailsPurchaseReturnRecord',
  components: {
    Table,
  },
  data() {
    return {
      // 表格数据
      listData: [
        {
          name: '球囊',
          version: 'GA8970',
          SKU: 'GA8970',
          unit: '个',
          num: 30,
          price: 3000,
          money: 3000,
          batch: 'GA8970',
          produced_data: '2020-02-23',
          expiry_data: '2020-02-23',
          vender: '波科国际医疗贸易(上海)有限公司',
          id: 'GA8970',
          id_validity: '2020-02-23',
          condition: '无',
          remark: '无',
        },
      ],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '产品编号',
          key: 'id',
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'version',
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'SKU',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
        },
        {
          title: '数量',
          key: 'num',
          align: 'center',
        },
        {
          title: '单价',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.price)])
          },
        },
        {
          title: '金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.money)])
          },
        },
        {
          title: '批号',
          key: 'batch',
          align: 'center',
        },
        {
          title: '生产日期',
          key: 'produced_data',
          align: 'center',
        },
        {
          title: '有效期/失效期',
          key: 'expiry_data',
          align: 'center',
        },
        {
          title: '生产厂家',
          key: 'vender',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'id',
          align: 'center',
        },
        {
          title: '注册证有效期',
          key: 'id_validity',
          align: 'center',
        },
        {
          title: '储运条件',
          key: 'condition',
          align: 'center',
        },
      ],
    }
  },
}
</script>

<style></style>
